<template>
    <body>
        <main id="main">
    
            <section>
                <div class="titulo">
                    <div class="margem container">
    
                        <h2>Cadastrar Visitante</h2>
                    </div>
                </div>
                <div class="grid-2 margem container">
                    <fieldset class="grid-2 bloco margem">
    
                        <div>
                            <label for="nomeCompleto">Nome Completo  <span style="color: var(--cor-erro);">*</span> </label>
                            <input type="text" id="nomeCompleto" v-model="nomeCompleto" autocomplete="off" required>
                            <p v-if="nomeCompletoValidationError" style="color: var(--cor-erro);">{{ nomeCompletoValidationError }}</p>
    
                        </div>
                        <div>
                            <label for="cpf">CPF</label>
                            <input type="text" id="CPF" v-model="CPF" @input="validarCPF" v-mask-cpf>
                            <p v-if="cpfValidationError" style="color: var(--cor-erro);">{{ cpfValidationError }}</p>
                        </div>
                        <div>
                            <label for="email">E-mail <span  v-b-tooltip.hover.top title="E-mail será utilizado para envio do QR Code para o visitante">
                                        <i class="fa-solid fa-circle-info"></i></span></label>
                            <input type="text" id="email" v-model="email" @input="validarEmail">
                            <span v-if="emailValidationError" style="color: var(--cor-erro);">{{ emailValidationError }}</span>
    
                        </div>
                        <div>
                            <label for="celular">Celular</label>
                            <input type="text" id="celular" v-model="celular" v-mask-phone.br>
                        </div>
                        <div>
                            <label for="info">Informações (Opcional)</label>
                            <textarea type="text" id="info" v-model="info"></textarea>
                        </div>
    
    
    
                        <div @click="salvarVisitante" class="submit m-b"><button>Salvar</button>
                            
                            <button @click="cancelarAcao" class="acao-secundaria">Cancelar</button>
                        </div>
    
                    </fieldset>
    
                    <div class="bloco margem alinha-centro">
    
                        <label>Foto do visitante</label>
                        <button @click="toggleCamera" v-if="!isCameraOpen"> Abrir câmera </button>
                        <button @click="toggleCamera" v-if="isCameraOpen && !isPhotoTaken"> Fechar câmera</button>
                        <button @click="captureImage" v-if="isCameraOpen">Tirar foto </button>
                        <button @click="discardImage" v-if="isCameraOpen"> Descartar foto </button>
                        <br><br>
    
                        <!-- avatar / mostra se = fotoPessoa é vazia E não tem foto tirada E a camera esta fechada-->
                        <div v-if="!fotoPessoa && !isPhotoTaken && !isCameraOpen">
                            <img src="../../../public/img/user-avatar.png" alt="Imagem em Base64">
                        </div>
                        <!-- camera -->
    
    
                        <video ref="video" autoplay v-if="isCameraOpen && !isPhotoTaken"></video>
                        <img :src="imageBase64" alt="" v-if="isPhotoTaken" style="max-width: 100%; max-height: 400px;" />
    
    
                        <!-- mostra a foto salva -->
                        <div v-if="fotoPessoa && !isCameraOpen && !isPhotoTaken">
                            <img :src="fotoPessoa" alt="foto visitante" />
                        </div>
    
    
    
    
    
                    </div>
                </div>
    
            </section>
        </main>
    </body>
</template>

<script>
import Visitante from '../../models/visitante.model';
import { createToaster } from "@meforma/vue-toaster";
// import api from '../../services/api';
import { api } from "roboflex-thalamus-request-handler"; 
import { sso } from "roboflex-thalamus-sso-lib";

const toaster = createToaster({
    position: "top-right",
});

export default {

    name: "VisitanteCadastro",

    components: {},

    data() {
        return {
            visitante: new Visitante({ id_setor: null }),
            modoCadastro: true,
            imageBase64: '',
            isCameraOpen: false,
            isPhotoTaken: false,
            video: null,
            image: null,
            nomeCompleto: '',
            sexo: '',
            CPF: '',
            email: '',
            celular: '',
            path_image: '',
            fotoPessoa: '',
            mostraFoto: false,
            imagePath: '',
            showToast: false,
            fotoAtualizada: null,
            cpfValidationError: null,
            emailValidationError: null,
            selectedDomain: '',
            nomeCompletoValidationError: '',
            info: ''

        }
    },


    watch: {
        email: function(newEmail) {
            this.validarEmail(newEmail);
        },
        CPF: function(newCPF) {
            this.validarCPF(newCPF);
        },

        nomeCompleto: function(newNomeCompleto) {
            this.validarNomeCompleto(newNomeCompleto);
        }

    },

    created() {
        let id = this.$route.params.id;
        if (id) {
            this.modoCadastro = false;
            this.obterPessoaId(id);
        } else {
            this.modoCadastro = true;
        }

        const usuarioLogado = sso.getUsuarioLogado();
            this.userName = usuarioLogado.nome;
            this.userId = usuarioLogado.id;
            this.local = usuarioLogado.local;

    },

    methods: {
        validarNomeCompleto(nomeCompleto) {
            this.nomeCompletoValidationError = null;

            const nomeCompletoArray = nomeCompleto.trim().split(' ');
            if (nomeCompletoArray.length < 2) {
                this.nomeCompletoValidationError = 'Nome completo inválido!';
            }
        },

        validarEmailDropdown() {
            this.emailValidationError = null;
            this.validarEmail();
        },

        validarEmail() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u;

            if (!emailRegex.test(this.email)) {
                this.emailValidationError = 'E-mail inválido';
            } else {
                this.emailValidationError = null;
            }
        },


        selecioneDominio(dominio) {
            this.email = this.email.split('@')[0] + '@' + dominio;
            this.selectedDomain = dominio;
            this.validarEmailDropdown();
        },

        validarCPF() {


            this.cpfValidationError = null;

            if (this.CPF == null) {
                return;
            }

            const cleanedCPF = this.CPF.replace(/\D/g, '');

            if (!cleanedCPF) {
                return;
            }

            if (cleanedCPF.length !== 11) {
                this.cpfValidationError = 'CPF deve conter 11 dígitos';
                return;
            }

            let sum = 0;
            for (let i = 1; i <= 9; i++) {
                sum += parseInt(cleanedCPF.charAt(i - 1)) * (11 - i);
            }

            let remainder = (sum * 10) % 11;
            if (remainder === 10 || remainder === 11) {
                remainder = 0;
            }

            if (remainder !== parseInt(cleanedCPF.charAt(9))) {
                this.cpfValidationError = 'CPF inválido';
                return;
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) {
                sum += parseInt(cleanedCPF.charAt(i - 1)) * (12 - i);
            }

            remainder = (sum * 10) % 11;
            if (remainder === 10 || remainder === 11) {
                remainder = 0;
            }

            if (remainder !== parseInt(cleanedCPF.charAt(10))) {
                this.cpfValidationError = 'CPF inválido';
                return;
            }
        },

        toggleCamera() {

            if (this.isCameraOpen) {
                this.closeCamera();
            } else {
                this.openCamera();
            }
        },

        openCamera() {
            this.isCameraOpen = true;
            this.$nextTick(() => {
                this.video = this.$refs.video;
                // Solicita acesso à webcam
                navigator.mediaDevices
                    .getUserMedia({
                        video: { width: 500, height: 400 }
                    })
                    .then((stream) => {
                        this.video.srcObject = stream;
                    })
                    .catch((error) => {
                        console.error('Erro ao acessar a webcam:', error);
                        toaster.show(`Acesso à webcam bloqueado. Permita o acesso à webcam`, { type: "error" });
                    });
            });
        },

        closeCamera() {
            this.isCameraOpen = false;
            if (this.video && this.video.srcObject) {
                const tracks = this.video.srcObject.getTracks();
                tracks.forEach(track => track.stop());
                this.video.srcObject = null;
            }
        },

        discardImage() {
            this.isPhotoTaken = false;
            this.imageBase64 = '';
            this.openCamera();
        },

        captureImage() {
            const canvas = document.createElement('canvas');
            canvas.width = this.video.videoWidth;
            canvas.height = this.video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(this.video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.imageBase64 = reader.result;
                    this.isPhotoTaken = true;
                    this.fotoAtualizada = true;

                    if (this.modoCadastro || !this.fotoPessoa) {
                        this.imagePath = null;
                    }
                };
                reader.readAsDataURL(blob);
            }, 'image/png');
        },

        obterPessoaId(id) {
            api.get(`visitante/${id}`)
                .then(response => {
                    const pessoaData = response.data;
                    this.imagePath = response.data.path_image;
                    this.nomeCompleto = pessoaData.nomeCompleto;
                    this.CPF = pessoaData.CPF;
                    this.sexo = pessoaData.sexo;
                    this.email = pessoaData.email;
                    this.celular = pessoaData.celular;
                    this.mostraFotoPessoa();
                })
                .catch(error => {
                    console.log(error);
                });

        },

        cancelarAcao() {
            this.visitante = new Visitante();
            this.isCameraOpen = false;
            this.imageBase64 = null;
            this.$router.push({ name: "VisitanteView" })

        },

        cadastrarVisitante() {
            let requestData = {
                nomeCompleto: this.nomeCompleto,
                sexo: this.sexo,
                CPF: this.CPF,
                email: this.email,
                celular: this.celular,
            };
            if (this.imageBase64) {
                requestData.base64 = this.imageBase64;
            }
            api.post(`visitante`, requestData)
                .then(response => {
                    console.log('Visitante cadastrado', response);
                    this.$router.push({ name: "VisitanteView" });
                })
                .catch(error => {
                    console.error('Erro ao cadastrar visitante:', error);
                    toaster.show(`Erro ao cadastrar visitante`, { type: "error" });
                });

            toaster.show(`Visitante cadastrado`, { type: "success" });
            this.isCameraOpen = false;
        },

        atualizarVisitante() {
            const id = this.$route.params.id;
            if (this.imageBase64) {
                // se houver nova foto, atualiza foto
                api.put(`visitante/${id}`, {
                        nomeCompleto: this.nomeCompleto,
                        base64: this.imageBase64,
                        sexo: this.sexo,
                        CPF: this.CPF,
                        email: this.email,
                        celular: this.celular,
                    })
                    .then(response => {
                        console.log('Visitante atualizado:', response);
                        this.$router.push({ name: 'VisitanteView' });
                        toaster.show(`Visitante atualizado`, { type: "success" });
                    })
                    .catch(error => {
                        console.error('Erro ao atualizar visitante:', error);
                        toaster.show(`Erro ao atualizar visitante`, { type: "error" });
                    });
            } else {
                // se nao houver foto nova, atualiza outros campos sem alterar foto
                api.put(`visitante/${id}`, {
                        nomeCompleto: this.nomeCompleto,
                        sexo: this.sexo,
                        CPF: this.CPF,
                        email: this.email,
                        celular: this.celular,
                    })
                    .then(response => {
                        console.log('Visitante atualizado:', response);
                        this.$router.push({ name: "VisitanteView" });
                        toaster.show(`Visitante atualizado`, { type: "success" });
                    })
                    .catch(error => {
                        console.error('Erro ao atualizar visitante:', error);
                        toaster.show(`Erro ao atualizar visitante`, { type: "error" });
                    });
            }
            this.closeCamera();
        },

        salvarVisitante() {

            if (this.emailValidationError) {
                toaster.show(`E-mail inválido. Por favor, corrija o e-mail antes de salvar.`, { type: "error" });
                return;
            }

            if (!this.nomeCompleto) {
                toaster.show(`Nome Completo não pode ser vazio`, { type: "error" });
                return;
            }

            if (this.modoCadastro) {
                this.cadastrarVisitante();
            } else {
                this.atualizarVisitante();
            }
        },

        mostraFotoPessoa() {
            try {
                if (this.imagePath) {

                    const urlfoto = process.env.VUE_APP_ROOT_STORAGE;
                    this.fotoPessoa = urlfoto + this.imagePath;
                    this.mostraFoto = true;
                } else {
                    console.error('Cadastro sem foto');

                }
            } catch (error) {
                console.error(error);
            }
        },
    }
}
</script>
