<template>
    <body>
        <main id="main">
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input v-model="filtroNome" @input="pesquisaComFiltro" type="text" placeholder="Pesquisar visitante por nome" />
                                <a class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Visitante</h2>
                    </div>
                </div>
                <div class="margem container">
                    <div class="bloco margem">
                        <table class="tabela alinha-centro">
                            <thead class="tabela alinha-centro">
                                <tr >
                                    <th>Nome</th>
                                    <th>CPF</th>
                                    <th>Celular</th>
                                    <th>E-mail</th>
                                    <th>Nova visita</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody class="tabela alinha-centro">
                                <tr v-for="item in visitantesFiltrados" :key="item.id" >
                                    <td>{{ item.nomeCompleto }}</td>
                                    <td>{{ item.CPF }}</td>
                                    <td>{{ item.celular }}</td>
                                    <td>{{ item.email }}</td>
                                    <td>
                                        <div>
                                            <!-- <button style="width: 8px; padding: 20px;" type="button" @click="abrirModal(item)" data-bs-toggle="tooltip" data-bs-placement="top" title="Clique para cadastrar visita">
                                                        <i class="fa-solid fa-plus"></i>
                                                    </button> -->
    
    
                                            <div>
                                              
                                                <a @click="abrirModal(item)" data-bs-toggle="tooltip" data-bs-placement="top" title="Clique para cadastrar visita"  class="icone-adicionar">  </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            <a @click="editarPessoa(item)" style="transform: scale(0.8) ;" data-bs-toggle="tooltip" data-bs-placement="top" title="Clique para editar visitante" class="icone-editar"></a> 
                                            <a @click="excluirPessoa(item.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Clique para excluir visitante" class="icone-lixeira"></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
    
                <!-- MODAL VISITANTE -->
                <div class="modal-mask" v-if="isOpen" @click="fecharModalFora">
                    <div class="modal-container">
                        <div class="d-flex justify-content-between">
                            <h3>Cadastrar visita para {{ pessoaNomeModal }}</h3>
                        </div>
                        <hr>
                        <div style="display: flex; flex-flow: column;">
                            <div style="display:flex; flex-flow: row;">
                                <div style="width: 45%; display: flex; flex-flow: column; padding: 15px 20px 0px 0px;">
                                    <div style="display: flex; flex-flow: row; margin-bottom: 15px;">
                                        <span>Validade</span>&nbsp;<span style="color: var(--cor-erro);">*</span>
                                    </div>
                                    <div style="display: flex; flex-flow: row;">
                                        <div>
                                            <input value="1" type="number" id="dias" size="sm" style="width:60px;" />
                                            <label for="dias">Dia(s)</label>
                                        </div>
                                        <div>
                                            <input type="number" max="24" id="horas" size="sm" value="0" style="width:60px;" />
                                            <label for="horas">Hora(s)</label>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-flow: column; padding-top: 45px;">
                                        <div style="display: flex; flex-flow: row; margin-bottom: 15px;">
                                            <span>Informações</span>
                                        </div>
                                        <div>
                                            <textarea id="info" rows="5" placeholder="Digite as informações sobre a visita (Opcional)"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; flex-flow: column; padding: 15px 20px 0px 0px;">
                                    <div style="margin-bottom: 15px;">
                                        <span>Setor</span>&nbsp;<span style="color: var(--cor-erro);">*</span>
                                    </div>
                                    <input v-model="filtroSetor" type="text" placeholder="Pesquisar" aria-label="Pesquisa" aria-describedby="basic-addon1" />
                                    <div class="setores-container">
                                        <table>
                                            <tr v-for="item in setoresFiltrados" :key="item.id">
                                                <td>
                                                    <input v-model="setoresVisitante" type="checkbox" :value="item.id">
                                                </td>
                                                <td style="max-height: 40px; overflow-y: auto;">
                                                    &nbsp;&nbsp;{{ item.nome }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div style="width: 50%; display: flex; flex-flow: column; padding: 15px 0px 0px 0px;">
                                    <div>
                                        <button type="button" @click="iniciaLeitor">
                                                    <i class="fa-regular fa-address-card"></i>&nbsp;&nbsp;Vincular QR Code
                                                </button>
                                    </div>
                                    <br>
                                    <div class="camera" style="border: solid; border-width: 1px; border-color: var(--color-fonte); border-radius: 10px;">
                                        <qrcode-stream v-if="cameraAberta && !qrcodeWebcam" @decode="onDecode" @init="onInit" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="button" @click="salvarVisita">
                                            <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                                            <span v-if="!loading">&nbsp;Salvar &nbsp;</span>
                                            <span v-if="loading">&nbsp;Salvando... &nbsp;</span>
                                        </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL VISITANTE -->
    
                <!-- MODAL EXCLUIR -->
                <div class="modal-mask" v-if="showModal" @click="fecharModalFora">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br>
                        <div class="modal-body">
                            <span>Confirma a exclusão do registro?</span>
                            <br><br>
                            <div class="modal-footer">
                                <button type="button" @click="confirmarExclusao">Confirmar</button>&nbsp;&nbsp;
                                <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL EXCLUIR -->
            </section>
        </main>
    </body>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
import { ref } from 'vue';
import WebSocketService from '../../services/websocketservice';
import { QrcodeStream } from 'vue-qrcode-reader';
import Pessoa from '@/models/Pessoa';
import Setores from '@/models/Setor';
import setorService from '../../services/setor-service';
import { api } from "roboflex-thalamus-request-handler";
import { sso } from "roboflex-thalamus-sso-lib";


const toaster = createToaster({
    position: "top-right",
});

export default {
    name: 'VisitanteView',
    components: {
        QrcodeStream,
    },
    setup() {
        const isOpen = ref(false);
        const openModal = () => {
            isOpen.value = true;
            return isOpen.value;
        };
        return {
            isOpen,
            openModal,
        };
    },
    data() {
        return {
            showModal: false,
            pessoaIDModal: null,
            pessoaNomeModal: null,
            pessoaCPFModal: null,
            pessoaEmail: null,
            filtroNome: '',
            visitantes: [],
            page: 1,
            lastPage: null,
            currentPage: null,
            totalPages: null,
            visitantesFiltrados: [],
            idToDelete: null,
            filtroSetor: '',
            setoresVisitante: [],
            mostraAlerta: false,
            mostraAlertaWebcam: false,
            wsService: new WebSocketService(),
            qrcodeWebcam: null,
            qrcodeCartao: '',
            cameraAberta: false,
            dadosVisitaModal: {},
            qrCodeEmail: '',
            loading: false,
            setores: [],
        };
    },
    created() {
        const usuarioLogado = sso.getUsuarioLogado();
        this.userName = usuarioLogado.nome; // nome
        this.userId = usuarioLogado.id; // id
        this.local = usuarioLogado.local;
        this.obterSetores();
    },
    mounted() {
        this.buscarTodosVisitantes();
        this.buscaLocal();

    },
    beforeUnmount() {
        this.wsService.removeListener(this.handleMessage);
        this.wsService.close();
    },
    computed: {
        setoresFiltrados() {
            return this.setores.filter(setor => {
                const buscaSetor = this.filtroSetor.toLowerCase();
                return (
                    (setor.nome && setor.nome.toLowerCase().includes(buscaSetor))
                );
            });
        },
    },
    methods: {
        adicionarPessoa() {
            this.$router.push({ name: "VisitanteCadastro" });
        },
        editarPessoa(pessoa) {
            this.$router.push({ name: "EditarVisitante", params: { id: pessoa.id } });
        },
        excluirPessoa(id) {
            this.idToDelete = id;
            this.showModal = true;
        },
        confirmarExclusao() {
            const id = this.idToDelete;
            const index = this.visitantes.findIndex(item => item.id === id);
            if (index !== -1) {
                api.delete(`visitante/${id}`)
                    .then(response => {
                        console.log('Response', response);
                        this.buscarTodosVisitantes(this.page);
                        this.showModal = false;
                    })
                    .catch(error => {
                        console.error(`Error`, error);
                        toaster.show(`Erro. Não foi possível excluir o visitante`, { type: "error" });
                    });
            }
            toaster.show(`Visitante excluído`, { type: "success" });
        },
        fecharModal() {
            this.isOpen = false;
            this.showModal = false;
        },
        ordenarPessoas(a, b) {
            return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0;
        },
        buscarTodosVisitantes() {
            const getAllPages = async () => {
                let currentPage = 1;
                let totalPages = 1;
                let todosVisitantes = [];

                while (currentPage <= totalPages) {
                    try {
                        const response = await api.get(`visitante?page=${currentPage}`);
                        const pessoas = response.data.data.map(p => new Pessoa(p));
                        todosVisitantes = todosVisitantes.concat(pessoas);
                        totalPages = response.data.last_page;
                        currentPage++;
                    } catch (error) {
                        console.error(`Error`, error);
                        toaster.show(`Erro ao buscar os visitantes`, { type: "error" });
                        break;
                    }
                }

                this.visitantes = todosVisitantes.sort(this.ordenarPessoas).reverse();
                this.pesquisaComFiltro();
                console.log('visitantes', this.visitantes);
            };

            getAllPages();
        },
        pesquisaComFiltro() {
            this.visitantesFiltrados = this.visitantes.filter(item =>
                item.nomeCompleto.toLowerCase().includes(this.filtroNome.toLowerCase())
            );
        },
        mostraGenero(generoAbreviado) {
            if (generoAbreviado === 'f') {
                return 'Feminino';
            } else if (generoAbreviado === 'm') {
                return 'Masculino';
            } else {
                return generoAbreviado;
            }
        },
        abrirModal(pessoa) {
            this.pessoaIDModal = pessoa.id;
            this.pessoaNomeModal = pessoa.nomeCompleto;
            this.pessoaCPFModal = pessoa.CPF;
            this.pessoaEmail = pessoa.email;
            this.isOpen = true;
        },
        fecharModalFora(event) {
            if (event.target === event.currentTarget) {
                this.fecharModal();
            }
        },
        async buscaLocal() {
            try {
                const response = await api.get(`/local`);
                this.localData = response.data;
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async alterarLocal() {
            if (this.localSelecionado !== null) {
                try {
                    const response = await api.get(`/local/${this.localSelecionado}/acessos-hoje`);
                    this.localData = response.data || [];
                } catch (error) {
                    console.error('Error', error);
                }
            }
        },
        obterSetores() {
            setorService.obterTodos()
                .then((response) => {
                    this.setores = response.data.map((p) => new Setores(p));
                })
                .catch(error => {
                    console.log(error)
                    toaster.show(`Erro ao carregar lista de setores"`, { type: "error" });
                })
        },
        iniciaLeitor() {
            this.wsService.addListener(this.handleMessage);

            const chamaLeitorQrcode = JSON.stringify({
                mensagem: 'iniciar_leitor',
                local_id: this.local
            });

            this.wsService.send(chamaLeitorQrcode);

            console.log('chamando websocket...', chamaLeitorQrcode);

            const timeout = 2000;
            const timeoutId = setTimeout(() => {
                console.log('tablet não respondeu, chamando webcam...')
                this.wsService.removeListener(this.handleMessage);
                this.iniciarLeituraWebcam();
            }, timeout);

            this.wsService.addListener(() => {
                clearTimeout(timeoutId);
                this.wsService.removeListener(this.handleMessage);
            });

            this.qrCodeCartao = '';
            this.mostraAlerta = false;
        },
        handleMessage(event) {
            try {
                if (event && event.data) {
                    const message = event.data;
                    const messageData = JSON.parse(message);
                    if (messageData.mensagem === "qr_capturado") {
                        this.qrCodeCartao = messageData.qrcode;
                        console.log('leu no tablet:', this.qrCodeCartao);
                        this.mostraAlerta = true;
                        toaster.show(`Qr code capturado`, { type: "success" });
                        return;
                    }
                } else {
                    console.log('Sem resposta do websocket');
                }
            } catch (error) {
                console.error(error);
            }
        },
        iniciarLeituraWebcam() {
            if (this.cameraPermissaoConcedida()) {
                this.cameraAberta = true;
            } else {
                console.log('Permissão para a webcam não concedida');
                toaster.show(`Por favor, conceda permissão para acessar a webcam`, { type: "error" });
            }
        },
        cameraPermissaoConcedida() {
            return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
        },
        vincularCartaoWebcam() {
            this.onDetect()
        },
        async onDecode(result) {
            try {
                this.qrCodeCartao = '';
                this.mostraAlerta = false;
                console.log('tem qr code?', this.qrCodeCartao);
                this.qrCodeCartao = result;
                console.log('lido na webcam:', this.qrCodeCartao);
                this.cameraAberta = false;
                toaster.show(`Qr code capturado`, { type: "success" });
            } catch (error) {
                console.error('Erro onDecode: ', error)
            }
        },
        async onInit(promise) {
            try {
                await promise;
                console.log('oninit ', promise);
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission";
                    console.log(this.error);
                    toaster.show(`Sem permissão para acessar a webcam`, { type: "error" });
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device";
                    console.log(this.error);
                    toaster.show(`Webcam não encontrada`, { type: "error" });
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)";
                    console.log(this.error);
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?";
                    console.log(this.error);
                    toaster.show(`Webcam está em uso`, { type: "error" });
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable";
                    console.log(this.error);
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser";
                    console.log(this.error);
                }
            }
        },
        salvarVisita() {
            this.loading = true;
            const pessoaID = this.pessoaIDModal;
            const pessoaNome = this.pessoaNomeModal;
            const pessoaEmail = this.pessoaEmail;

            if (this.setoresVisitante.length === 0) {
                toaster.show(`Selecione um setor para a visita.`, { type: "error" });
                this.loading = false;
                return;
            }

            const dias = document.getElementById('dias').value;
            const horas = document.getElementById('horas').value;
            if ((!horas || horas <= 0) && (!dias || dias <= 0)) {
                toaster.show(`Informe a validade da visita`, { type: "error" });
                this.loading = false;
                return;
            }

            if (!pessoaEmail && !this.qrCodeCartao) {
                toaster.show(`Informe o e-mail ou leia um QR Code para a visita.`, { type: "error" });
                this.loading = false;
                return;
            }

            const dataCapturada = new Date();
            const dataFormatada = dataCapturada.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).replace(/[^\d]/g, '');

            this.qrCodeEmail = 'VX' + pessoaID + pessoaNome.replace(/\s/g, '') + this.setoresVisitante.join('') + this.local + dataFormatada.replace(/\s/g, '');

            this.dadosVisitaModal = {
                "pessoa_id": pessoaID,
                "qrcode": this.qrCodeEmail,
                "info": document.getElementById('info').value,
                "setor_id": this.setoresVisitante,
                "val_dias": document.getElementById('dias').value,
                "val_horas": document.getElementById('horas').value,
                "local_id": this.local,
                "qrcode_fisico": this.qrCodeCartao,
                "base64": this.pessoaImgBase64
            };

            console.log('qrcodemail ', this.qrCodeEmail);

            api.post(`/visita`, this.dadosVisitaModal)
                .then(response => {
                    if (response.data.cod === 1) {
                        toaster.show(`QR Code de visitante inválido. Tente outro QR Code`, { type: "error" });
                        this.qrCodeCartao = '';
                    } else {
                        if (!this.pessoaEmail && this.qrCodeCartao) {
                            toaster.show(`Visita cadastrada`, { type: "success" });
                        } else if (this.pessoaEmail && this.qrCodeCartao) {
                            toaster.show(`Visita cadastrada. QR Code enviado para o e-mail cadastrado`, { type: "success" });
                        } else if (this.pessoaEmail && !this.qrCodeCartao) {
                            toaster.show(`Visita cadastrada. QR Code enviado para o e-mail cadastrado`, { type: "success" });
                        }
                    }

                    this.setoresVisitante = [];
                    this.qrCodeCartao = '';
                    this.loading = false;
                    this.cameraAberta = false;
                    this.isOpen = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                    toaster.show(`Erro ao cadastrar visita`, { type: "error" });
                });
        },
    }
};
</script>

<style scoped>
.setores-container {
    display: flex;
    height: 210px;
    border-radius: 5px;
    border-width: 1px;
    overflow-y: auto;
}

.camera {
    overflow: hidden;
    position: relative;
    height: 250px;
    width: 250px;
}
</style>
