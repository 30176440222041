<template>
   <body>
        <headerComponent></headerComponent>

        <main id="main">
            <menuLateralComponentVue></menuLateralComponentVue>
            <section>
                <router-view/>
            </section>
            </main>

       </body>
</template>

<script>
import menuLateralComponentVue from "./components/menuLateral/menuLateralComponent.vue"
import headerComponent from "./components/header/HeaderComponent.vue"

export default ({
    data() {
        return {

        }
    },

    components: {
        menuLateralComponentVue: menuLateralComponentVue,
        headerComponent: headerComponent
    }

})
</script>


<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    background-color: var(--cor-bg);
    border-radius: 8px;
    padding: 100px;
    width: 90%;
    max-width: 1500px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}
</style>