
import { createRouter, createWebHashHistory } from 'vue-router'
import { sso } from "roboflex-thalamus-sso-lib";



//Acesso
import AcessoView from '../views/Acesso/AcessoView.vue'
import VisitanteView from '../views/Acesso/VisitanteView.vue'
import VisitanteCadastro from '../views/Acesso/VisitanteCadastro.vue'
import PesquisaView from '../views/Acesso/PesquisaView.vue'
//Permissão
import ColaboradorView from "@/views/Permissão/ColaboradorView"
import ControleDeColaboradorRH from "@/views/Permissão/ControleDeColaborador"
import ColaboradorRecepcaoView from "@/views/Permissão/ColaboradorRecepcaoView"


function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
   

    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}

const routes = [

  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },

  //Acesso
  {
    path: '/',
    name: 'AcessoView',
    component: AcessoView,
    beforeEnter: guardMyroute,

  },
  {
    path: '/visitante',
    name: 'VisitanteView',
    component: VisitanteView,
    beforeEnter: guardMyroute
  },
  {
    path: '/visitante/novo',
    name: 'VisitanteCadastro',
    component: VisitanteCadastro,
    beforeEnter: guardMyroute
  },
  {
    path: '/visitante/editar/:id',
    name: 'EditarVisitante',
    component: VisitanteCadastro,
    beforeEnter: guardMyroute

  },
  {
    path: '/pesquisa',
    name: 'PesquisaView',
    component: PesquisaView,
    beforeEnter: guardMyroute

  },
  //Permissão
  {
    path: '/colaboradores-rh',
    name: 'ControleDeColaboradorRH',
    component: ControleDeColaboradorRH,
    beforeEnter: guardMyroute

   
  },
  {
    path: '/colaboradores/novo',
    name: 'AdicionarColaborador',
    component: ColaboradorView,
    title: 'Adicionar Colaborador',
    beforeEnter: guardMyroute
   
  },
  {
    path: '/colaboradores/editar/:id',
    name: 'ColaboradorEditar',
    component: ColaboradorView,
    title: 'Editar Colaborador',
    beforeEnter: guardMyroute
   
  },
  {
    path: '/colaboradores/excluir',
    name: 'ExcluirColaborador',
    component: ColaboradorView,
    title: 'Excluir Colaborador',
    beforeEnter: guardMyroute
   
  },
  {
    path: '/colaboradores',
    name: 'ControleDeColaborador',
    component: ColaboradorRecepcaoView,
    title: 'Colaborador Recepção',
    beforeEnter: guardMyroute
   
  },


  //  { 
  //   path: '/:pathMatch(.*)*', redirect: '/home' 
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})



export default router
