import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueMask from '@devindex/vue-mask'; 
import axios from 'axios';
import store from './store';
import './assets/css/thalamus.css'


axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;


const app = createApp(App).use(store)
app.use(router)
app.use(VueMask);



app.mount('#app')
