<template>
    <body>
        <main id="main">
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input v-model="filtroNome" @input="pesquisaComFiltro" type="text" class="form-control" placeholder="Pesquisar acessos" />
                                <a class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Acessos Recentes</h2>
                    </div>
                </div>
                <div class="grid-2 margem container">
                    <div class="bloco margem">
                        <table class="tabela">
                            <thead>
                                <tr class="alinha-centro">
                                    <th>Nome</th>
                                    <th>Data/Hora</th>
                                    <th>Entrada/Saída</th>
                                    <th>Empresa</th>
                                </tr>
                            </thead>
                            <tbody v-if="acessos.length > 0">
                                <tr v-for="acesso in acessos" :key="acesso.id">
                                    <td>{{ acesso.nomeCompleto }}</td>
                                    <td>{{ formatarDataHora(acesso.created_at) }}</td>
                                    <td class="alinha-centro">
                                        <span v-if="acesso.sentido === 'e'" style="color:var(--cor-sucesso)"><i class="fa-solid fa-door-open"></i>&nbsp;</span>
                                        <span v-if="acesso.sentido === 's'" style="color: var(--cor-erro)"><i class="fa-solid fa-person-walking-arrow-right"></i>&nbsp;</span>
                                    </td>
                                    <td>{{ acesso.local_nome }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4" class="alinha-centro">Nenhum acesso registrado</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bloco margem ">
                        <div class="alinha-centro">
                            <div style="border: solid 1px lightgray; border-radius: 20px;">
                                <div class="foto-ultimo">
                                    <img v-if="mostraFoto" :src="fotoPessoa" alt="Foto Acesso" />
                                    <img src="../../../public/img/user-avatar.png" v-if="!mostraFoto" alt="Foto Acesso" />
                                </div>
                            </div>
                            <div class="acesso-permitido alinha-centro" v-if="mostraFoto">
                                <p>Acesso permitido</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </body>
    </template>
    
    <script>
    import api from '../../services/api';
    import urlFoto from '../../services/api';
    import WebSocketService from '../../services/websocketservice';
    import { createToaster } from "@meforma/vue-toaster";
    import { sso } from "roboflex-thalamus-sso-lib";

    
    const toaster = createToaster({
        position: "top-right",
        duration: "4000",
    });
    
    export default {
        name: 'AcessoView',
    
        components: {},
    
        data() {
            return {
                acessos: [],
                message: '',
                fotoPessoa: '',
                mostraFoto: false,
                filtro: '',
                filtroNome: '',
                pageAcesso: 1,
                local: null,
                localData: [],
                wsService: new WebSocketService(),
                apiUrl: api.defaults.baseURL,
                urlFoto: urlFoto.caminhoFoto,
                lastPage: null,
                currentPage: null,
                totalPages: null,
                page: 1,
            };
        },
    
        created() {
            const usuarioLogado = sso.getUsuarioLogado();
            this.userName = usuarioLogado.nome;
            this.userId = usuarioLogado.id;
            this.local = usuarioLogado.local;

    
            this.wsService.addListener(this.handleMessage);
        },
    
        beforeUnmount() {
            this.wsService.removeListener(this.handleMessage);
            this.wsService.close();
        },
    
        mounted() {
            this.buscaLocal();
            this.buscaAcessos();
        },
    
        watch: {
            filtroNome: {
                handler: 'pesquisaComFiltro',
                immediate: true,
            },
        },
    
        methods: {
    
            pesquisar() {
                this.$router.push({ name: "PesquisaView" });
            },
    
            async buscaAcessos() {
                try {
                    const response = await api.get(`local/${this.local}/acessos-hoje`);
                    this.acessos = response.data;
                } catch (error) {
                    console.error('Erro:', error);
                    toaster.show(`Erro ao buscar acessos`, { type: "error" });
                }
            },
    
            pesquisaComFiltro() {
                const termoPesquisa = this.filtroNome.toLowerCase();
    
                if (!termoPesquisa) {
                    this.buscaAcessos();
                    return;
                }
    
                this.acessos = this.acessos.filter(acesso => {
                    return acesso.nomeCompleto.toLowerCase().includes(termoPesquisa);
                });
            },
    
            replaceIcon() {
                this.results = this.text.replace(this.text, this.newText);
            },
    
            formatarDataHora(data) {
                const date = new Date(data);
                return date.toLocaleString();
            },
    
            async alterarLocal() {
                if (this.local !== null) {
                    try {
                        const response = await api.get(`local/${this.local}/acessos-hoje`);
                        this.acessos = response.data.data || [];
                    } catch (error) {
                        console.error('Error ao alterar empresa', error);
                        toaster.show(`Erro alterar empresa`, { type: "error" });
                    }
                }
            },
    
            async buscaLocal() {
                try {
                    const response = await api.get('local');
                    this.localData = response.data;
                } catch (error) {
                    console.error('Erro ao buscar empresas', error);
                    toaster.show(`Erro ao buscar empresa`, { type: "error" });
                }
            },
    
            async handleMessage(event) {
                this.message = event.data;
    
                try {
                    const messageData = JSON.parse(this.message);
    
                    if (messageData.mensagem === "acesso_autorizado" && messageData.visitante === 1) {
                        const visitanteInfo = await api.get(`visitante/${messageData.pessoa_id}`);
    
                        if (visitanteInfo.data.path_image) {
                            const urlfoto = process.env.VUE_APP_ROOT_STORAGE;
    
                            this.fotoPessoa = urlfoto + visitanteInfo.data.path_image;
                            this.mostraFoto = true;
                            await this.buscaAcessos();
                        }
                    }
    
                    if (messageData.mensagem === "acesso_autorizado" && messageData.visitante === 0) {
                        const colaboradorInfo = await api.get(`pessoa/${messageData.pessoa_id}`);
    
                        if (colaboradorInfo.data.path_image) {
                            const urlfoto = process.env.VUE_APP_ROOT_STORAGE;
    
                            this.fotoPessoa = urlfoto + colaboradorInfo.data.path_image;
                            this.mostraFoto = true;
                            await this.buscaAcessos();
                        }
                    } else if (messageData.mensagem === "acesso_negado") {
                        this.mostraFoto = false;
                    }
                } catch (error) {
                    console.error('Mensagem não reconhecida no Acesso', error);
                }
            },
        },
    };
    </script>
    
    <style scoped>
     .card-foto {
         min-height: 500px;
     }
    
     .foto-ultimo img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        display: block;
        margin: 0 auto;
    }
    
     .acesso-permitido {
         border-radius: 10px;
         color: var(--cor-sucesso);
         border: 1px solid lightgray;
         font-weight: bold;
     }
    </style>
    